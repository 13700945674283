@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
* {
  font-family:  'Roboto', sans-serif;
}

.ant-avatar > img{
  object-fit: contain;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.ant-notification-notice-warning{
  background: #FFEDCA !important;
  border: 1px solid #FFB82E !important;
  & div{
    color: #58585A;
    font-weight: 400;
    font-size: 20px;
  }
}
.ant-notification-notice-success{
  background: #F1FFF4 !important;
  border: 1px solid #C0ECD4 !important;
  & div{
    font-weight: 400;
    font-size: 20px;
    color: #58585A;
  }
}
.ant-notification-notice-info{
  background: #2C5C86 !important;
  border: 1px solid #2C5C86 !important;
  & div{
    color: white !important;
    font-weight: 400;
    font-size: 20px;
  }
}
.ant-notification-notice-error{
  background: #FFF5F5 !important;
  border: 1px solid #FFD8D8 !important;
  & div{
    color: #58585A !important;
    font-weight: 400;
    font-size: 20px;
  }
}